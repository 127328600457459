import ChartController from '@stimulus-components/chartjs'
import { Chart } from 'chart.js/auto'

const formattedDate = (date) => {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000
  const correctDate = new Date(date.getTime() + userTimezoneOffset)
  return `${correctDate.toLocaleString('default', { month: 'short' })} ${correctDate.getDate()}, ${correctDate.getFullYear()}`
}

export default class extends ChartController {
  connect() {
    const element = this.hasCanvasTarget ? this.canvasTarget : this.element
    this._setupChart(element)
  }

  _setupChart(canvas) {
    const legendPaddingPlugin = {
      beforeInit(chart) {
        const originalFit = chart.legend.fit

        chart.legend.fit = function fit() {
          originalFit.bind(chart.legend)()
          this.height += 15
        }
      }
    }

    this.chart = new Chart(canvas.getContext('2d'), {
      type: this.typeValue,
      data: this.chartData,
      options: this.chartOptions,
      plugins: [legendPaddingPlugin]
    })

    this._setLineColors()
  }

  _setLineColors() {
    this.chart.data.datasets.forEach((dataset) => {
      const lineColor = getComputedStyle(document.documentElement).getPropertyValue(dataset.borderColor)
      dataset.borderColor = lineColor
      dataset.backgroundColor = lineColor
    })
    this.chart.update()
  }

  get defaultOptions() {
    return {
      maintainAspectRatio: false,
      scales: {
        x: {
          ticks: {
            maxTicksLimit: 3,
            callback: this._xTickLabel
          }
        },
        y: {
          ticks: {
            maxTicksLimit: 4
          }
        }
      },
      plugins: {
        tooltip: {
          displayColors: false,
          callbacks: {
            title: this._tooltipTitle
          }
        }
      }
    }
  }

  _xTickLabel(value) { return formattedDate(new Date(this.getLabelForValue(value))) }
  _tooltipTitle(tooltipItem) { return formattedDate(new Date(tooltipItem[0].label)) }
}
