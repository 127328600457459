import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

export default class extends Controller {
  static targets = ['dropdown']
  static values = {
    url: String,
    valueField: {
      type: String,
      default: 'id'
    },
    labelField: {
      type: String,
      default: 'name'
    },
    searchField: {
      type: Array,
      default: ['name']
    }
  }

  connect() {
    this.tomSelect = new TomSelect(this.dropdownTarget, {
      ...this.baseSettings,
      ...this.apiSettings
    })
  }

  disconnect() {
    this.tomSelect.destroy()
  }

  async loadFunction(query, callback) {
    const url = `${this.urlValue}?query=${encodeURIComponent(query)}`
    const response = await fetch(url, this.fetchConfig)
    const json = await response.json()
    callback(json)
  }

  get baseSettings() {
    const settings = {
      create: false,
      plugins: ['remove_button'],
      valueField: this.valueFieldValue,
      labelField: this.labelFieldValue,
      searchField: this.searchFieldValue
    }
    if (this.dropdownTarget.multiple) {
      settings.maxItems = this.dropdownTarget.dataset?.maxitems ?? null
    }
    return settings
  }

  get apiSettings() {
    if (this.hasUrlValue) {
      return { load: this.loadFunction.bind(this) }
    }
    return {}
  }

  get fetchConfig() {
    return {
      headers: { Accept: 'application/json' }
    }
  }
}
