import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container', 'input']

  remove() {
    this.inputTarget.value = 'true'
    this.containerTarget.classList.add('display-none')
  }
}
